import React, { Component } from 'react';

/* THIS PAGE HAS BEEN MOVED TO WRITING.HUDL */
export default class HeadingsLists extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.___navigate('https://hudl.com/design/writing/best-practices/lists');
  }

  render() {
    return <div />;
  }
}
